import React, { useState, useEffect } from 'react';
import failed from "./img/failed3.svg"
import { useLocation } from 'react-router-dom';
import { DOMAIN_BACKEND } from '../config/config';

type ContentStage = 0 | 1 | 2 | 3;

const ShowContent: React.FC = () => {
    const [contentStage, setContentStage] = useState<ContentStage>(0);
    const [hasFetched, setHasFetched] = useState<boolean>(false);
    const [passwordIn, setPassword] = useState("");
    const [content, setContent] = useState("");
    const [file, setFile] = useState<Blob>(new Blob);
    const path = useLocation();

    const fetchFunction1 = async (path: string) => {
        setHasFetched(true);
        let id = path.split("/")[2];
        fetch((DOMAIN_BACKEND + "/private/permission"), {
            headers: {
                'Content-type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({ id })
        })
            .then(response => response.json())
            .then(async data => {
                if (data.permission == true) {
                    setContentStage(0);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    setContentStage(1);
                }
                else {
                    setContentStage(3);
                }
            });
    };

    const fetchFunction2 = async (path: string) => {
        let id = path.split("/")[2];
        fetch((DOMAIN_BACKEND + "/private/checkLogin"), {
            headers: {
                'Content-type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({ id, passwordIn })
        })
            .then(response => response.json())
            .then(async data => {
                if (data.permission == true) {
                    setContentStage(0);
                    setContent(data.content);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    setContentStage(2);
                }
                else {
                    setContentStage(3);
                }
            });
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!hasFetched) {
                setHasFetched(true);
                await fetchFunction1(path.pathname);
            }
        };
        fetchData();
    }, []);

    switch (contentStage) {
        case 1:
            return (
                <div className='showContent'>
                    <div id='showContent-container'>
                        <div id='succ-con'>
                            <div className="div1">
                                <span id="header">Login with your personal password</span>
                            </div>
                            <div className="div3">
                                <span id="txt-passw">Enter your private password:</span>
                                <input type="password" onChange={e => setPassword(e.target.value)} />
                                <button onClick={(e) => fetchFunction2(path.pathname)}>Login</button>
                            </div>
                            <div className="div2">
                                <span id="firma">privatetransfer by cfranke</span>
                            </div>
                        </div>
                    </div>
                </div>
            );
        case 2:
            return (
                <div className='showContent'>
                    <div id='showContent-container'>
                        <div id="content">
                            <div className="div1">
                                <span id='span1'>Content:</span>
                            </div>
                            <div className="div3">
                                <textarea name="Content" id="content-output" value={content} readOnly></textarea>
                            </div>
                            <div className="div2">
                                <span id="warning" >Warning: Private content will be removed in 10min.</span>
                                <span id="firma">privatetransfer by cfranke</span>
                            </div>
                        </div>
                    </div>
                </div>
            );
        case 3:
            return (
                <div className='showContent'>
                    <div id='showContent-container'>
                        <div id='failed-con'>
                            <img src={failed} />
                            <span>No Permisson!</span>
                            <span>Please try again.</span>
                        </div>
                    </div>
                </div>
            );
        default:
            return (
                <div className='showContent'>
                    <div id='showContent-container'>
                        <div id='loading'>
                            <div className="load"></div>
                            <span>Loading...</span>
                            <span id="firma">privatetransfer by cfranke</span>
                        </div>
                    </div>
                </div>
            );
    }
}

export default ShowContent;
