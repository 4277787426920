import React, { useState } from 'react';
import Clock from 'react-live-clock';
import logo from './Logo_Homepage.png';
import { useUserContext } from '../auth/UserContext';
import { useNavigate } from 'react-router-dom';
import { DOMAIN_BACKEND } from '../config/config';

const Header = () => {
    const [username, setUsername] = useState("");
    const [passwordIn, setPassword] = useState("");
    const navigate = useNavigate();
    const { isLoggedIn, setIsLoggedIn } = useUserContext();
    const { isAdmin, setIsAdmin } = useUserContext();

    const getLoginData = () => {
        fetch((DOMAIN_BACKEND + "/user/login"), {
            headers: {
                'Content-type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({ username, passwordIn })
        })
            .then(response => response.json())
            .then(data => {
                if (data.token != null) {
                    setIsLoggedIn(true);
                    setIsAdmin(data.admin)
                } else {
                    alert("Anmeldung war nicht korrekt!");
                }
            });
    }

    const handleLogoff = () => {
        setIsLoggedIn(false);
        navigate("/");
    }

    return (
        <div className="header">
            <div id='container'>
                <div id='cont-logo'>
                    <img id='header-img' src={logo} alt="Logo_Homepage" onClick={() => navigate('/')} />
                    <span><i>privatetransfer</i> by cfranke</span>
                </div>
                <div id='header-clock'>
                    <Clock format={'HH:mm:ss'} ticking={true} />
                </div>
                <div id='header-login'>
                    {isLoggedIn ? (
                        <>
                            <div id="login-con">
                                <div id='header-user'>Benutzer: {username}</div>
                                <button onClick={handleLogoff}>Logoff</button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div id='login-con'>
                                <input type="text" placeholder="Nutzername" onChange={e => setUsername(e.target.value)} />
                                <input type="password" placeholder="Passwort" onChange={e => setPassword(e.target.value)} />
                                <button onClick={getLoginData}>Login</button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Header;
