import React, { useEffect, useState } from 'react';
import { useUserContext } from '../auth/UserContext';
import { DOMAIN_BACKEND } from '../config/config';

function Config() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [domain, setDomain] = useState("");
    const [admin, setAdmin] = useState(false);
    const { isLoggedIn } = useUserContext();

    useEffect(() => {
        getDomain();
    }, []);

    if (!isLoggedIn) {
        return (
            <div className='config'>
                <span id='config-header'>Einstellungen</span>
            </div>
        );
    }

    const registerUser = () => {
        if (username === "" || password === "") {
            alert("Bitte füllen Sie sowohl Benutzername als auch Passwort aus.");
            return;
        }

        fetch(DOMAIN_BACKEND + "/user/register", {
            headers: {
                'Content-type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({ username: username, password: password, admin: admin })
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    alert("User erfolgreich registriert!");
                } else {
                    alert("Es gab ein Problem bei der Registrierung des Benutzers.");
                }
            });
    }

    const getDomain = () => {
        fetch(DOMAIN_BACKEND + "/domain/get", {
            method: 'GET'
        })
            .then(response => response.json())
            .then(data => {
                if (data.domain) {
                    setDomain(data.domain);
                } else {
                    alert("no domain saved!");
                }
            });
    }

    const saveDomain = () => {
        if (domain === "") {
            alert("Please enter domain prefix");
            return;
        }

        fetch(DOMAIN_BACKEND + "/domain/save", {
            headers: {
                'Content-type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({ domain: domain })
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    alert("Successfully saved!");
                } else {
                    alert("Error occured!");
                }
            });
    }

    return (
        <div className='config'>
            <span id='config-header'>Einstellungen</span>
            <div id="config-user">
                <span>Nutzername:</span>
                <input type="text" placeholder="Nutzername" value={username} onChange={(e) => setUsername(e.target.value)} />
                <span>Password:</span>
                <input type="password" placeholder="Passwort" value={password} onChange={(e) => setPassword(e.target.value)} />
                <span>Admin:</span>
                <input type="checkbox" checked={admin} onChange={(e) => setAdmin(e.target.checked)} />
                <button onClick={registerUser}>Hinzufügen</button>
            </div>
            <div id='config-domain-prefix'>
                <span>Domain prefix: </span>
                <input id='domain-prefix' type="text" value={domain} onChange={(e) => setDomain(e.target.value)} />
                <button id="save-prefix">Save</button>
            </div>
        </div>
    );
};

export default Config;
