import React from 'react';
import { Link } from 'react-router-dom';
import logo from './img/Logo_Homepage.svg';
import logoP from './img/Logo_Homepage.png';
import locked from './img/padlock.png';

const Logoff = () => {

    return (
        <div className='logoff'>
            <div id='logoff-container'>
                <img id='logoff-logop' src={logoP} alt="Logo_Homepage" />
                <img id='logoff-logo' src={logo} alt="Logo_Homepage" />
            </div>
        </div>
    );
};

export default Logoff;
