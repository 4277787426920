import React, { useEffect, useState } from 'react'
import { DOMAIN_BACKEND } from '../config/config';

interface Password {
  comment: string;
  uri: string;
  opend: string;
  active: boolean;
}

function ManagePasswords() {
  const [passwords, setPasswords] = useState<Password[]>([]);

  const fetchPasswords = async () => {
    fetch((DOMAIN_BACKEND + "/private/getAll"), {
      method: 'GET'
    })
      .then(response => response.json())
      .then(async data => {
        setPasswords(data.pass)
      });
  };

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      alert('URL copied to clipboard');
    } catch (err) {
      alert('Failed to copy URL');
    }
  };


  useEffect(() => {
    fetchPasswords();
  }, []);

  return (
    <div className='managePasswords'>
      <div id='manage-passwords-container'>
        <button id="reload" onClick={(e) => fetchPasswords()}>Reload</button>
        <table id="table">
          <thead>
            <tr>
              <th>Comment</th>
              <th>URL</th>
              <th>Opend</th>
              <th>Active</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            {
              passwords.map((password, index) => (
                <tr key={index}>
                  <td>{password.comment || "none"}</td>
                  <td>{password.uri}</td>
                  <td>{password.opend?.replace("T", " ").replace("Z", "") || "not opend"}</td>
                  <td>{password.active == true ? "Yes" : "No"}</td>
                  <td className="button-container">
                    <button onClick={() => copyToClipboard(password.uri)}>Copy URL</button>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ManagePasswords