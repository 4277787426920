import React, { useState } from "react";
import "./App.css";
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  useNavigate,
  useLocation,
  createHashRouter
} from "react-router-dom";

import Navbar from "./components/Navbar";
import Header from "./components/Header";
import Logoff from "./pages/Logoff";
import Logon from "./pages/Logon";
import Config from "./pages/Config";

import { UserProvider } from "./auth/UserContext";
import { useUserContext } from "./auth/UserContext";

import "./styles/style.scss";
import "./styles/navbar.scss";
import "./styles/logoff.scss";
import "./styles/logon.scss";
import "./styles/statistics.scss";
import "./styles/createPassword.scss";
import "./styles/showContent.scss";
import "./styles/managePasswords.scss";
import "./styles/config.scss";
import ManagePasswords from "./pages/ManagePasswords";
import CreatePassword from "./pages/CreatePassword";
import ShowContent from "./pages/ShowContent";
import { formLabelClasses } from "@mui/material";

const Layout = () => {
  const { isLoggedIn, isAdmin } = useUserContext();
  let path = useLocation();
  let isPrivatePath = false;
  if (path.pathname.startsWith("/private/")) {
    isPrivatePath = true;
  }

  return (
    <>
      {!isPrivatePath && <Header />}
      {!isPrivatePath && <Navbar />}
      {isLoggedIn || isPrivatePath ? <Outlet /> : <Logoff />}
    </>
  );
};

const router = createHashRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Logon />,
      },
      {
        path: "/configView",
        element: <Config />,
      },
      {
        path: "/managePasswords",
        element: <ManagePasswords />,
      },
      {
        path: "/createPassword",
        element: <CreatePassword />,
      },
      {
        path: "/private/:id",
        element: <ShowContent />,
      }
    ],
  },
]);

function App() {
  return (
    <div className="app">
      <div className="container">
        <UserProvider>
          <RouterProvider router={router} />
        </UserProvider>
      </div>
    </div>
  );
}

export default App;
