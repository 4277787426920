import React from "react";
import { Link } from "react-router-dom";
import { useUserContext } from "../auth/UserContext";
import { useNavigate } from "react-router-dom";

function Navbar() {
  const navigate = useNavigate();
  const { isLoggedIn } = useUserContext();

  if (!isLoggedIn) {
    return (
      <div className="navbar">
        <div id="container"></div>
      </div>
    );
  }

  return (
    <div className="navbar">
      <div id="container">
        <div id="navbar-buttons">
          <button type="button" onClick={() => navigate("/createPassword")}>
            Create Password
          </button>
          <button type="button" onClick={() => navigate("/managePasswords")}>
            Manage Passwords
          </button>
        </div>
        <div id="navbar-config">
          <button type="button" onClick={() => navigate("/configView")}>
            Einstellungen
          </button>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
