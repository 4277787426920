import React, { ChangeEvent, useRef, useState } from 'react';
import greenConfirm from "./img/green_hacken.png";
import { DOMAIN_BACKEND } from '../config/config';

function CreatePassword() {
    const [postContent, setPostContent] = useState('');
    const [postPassword, setPostPassword] = useState('');
    const [comment, setComment] = useState("");
    const [GeneratedLink, setGeneratedLink] = useState('');
    const [showConfirmImage, setShowConfirmImage] = useState(false);
    const [showConfirmImage2, setShowConfirmImage2] = useState(false);
    const [file, setFile] = useState<File | null>(null);

    function GeneratePrivateLink() {
        if (postContent === "" || postPassword === "") {
            alert("Please fill content and password!");
            return;
        }
        const formData = new FormData();
        formData.append('content', postContent);
        formData.append('password', postPassword);
        formData.append('comment', comment);
        if (file) formData.append('file', file);

        fetch(DOMAIN_BACKEND + "/link/create", {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                setPostPassword("");
                setComment("");
                setGeneratedLink(data.data);
                if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                }
                setShowConfirmImage(true);
                setTimeout(() => {
                    setShowConfirmImage(false);
                }, 2000);
            });
    }

    const fileInputRef = useRef<HTMLInputElement>(null);
    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files[0]) {
            const file = files[0];
            if (file.size > 10485760) { // 10 MB
                alert("File is too big! max 10MB");
                if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                }
                return;
            } else {
                setFile(file);
            }
        }
    };

    return (
        <div className='createPassword'>
            <div id='create-password-container'>
                <h1 id="headline">Create Password</h1>
                <div id='header-cont'>
                    <span>Insert Content:</span>
                    <textarea name="Content" id="content-input" onChange={e => setPostContent(e.target.value)}></textarea>
                    <div id="file-upload-cont">
                        <span>Upload File:</span>
                        <input id="file-input" type="file" onChange={handleFileChange} ref={fileInputRef} />
                    </div>
                </div>
                <div className="submit-cont">
                    <div id="confirm">
                        <span>Comment:</span>
                        <input type="text" id="comment" value={comment} onChange={e => setComment(e.target.value)} />
                    </div>
                    <div id="confirm">
                        <span>Password:</span>
                        <input type="password" id='password-to-link' value={postPassword} onChange={e => setPostPassword(e.target.value)} />
                        <div id='confirm'>
                            <button id='generate-link' onClick={() => GeneratePrivateLink()}>Generate Private Link</button>
                            {showConfirmImage && <img src={greenConfirm} alt="logo" id='confirm-png' />}
                        </div>
                    </div>
                    <div id="confirm">
                        <textarea id="link-output" value={GeneratedLink} readOnly ></textarea>
                        <button id="copie-link" onClick={() => {
                            navigator.clipboard.writeText(GeneratedLink); setShowConfirmImage2(true); setTimeout(() => {
                                setShowConfirmImage2(false);
                            }, 2000); setGeneratedLink("");
                        }}>Copy</button>
                        {showConfirmImage2 && <img src={greenConfirm} alt="logo" id='confirm-png' />}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default CreatePassword;
