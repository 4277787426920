import React from 'react';
import { Link } from 'react-router-dom';
import logo from './img/Logo_Homepage_Open.svg';
import logoP from './img/Logo_Homepage.png';
import { useUserContext } from '../auth/UserContext';
import Logoff from './Logoff';

const Logon = () => {
    const { isLoggedIn } = useUserContext();
    if (!isLoggedIn) {
        return (
            <Logoff />
        );
    }
    return (
        <div className='logon'>
            <div id='logon-container'>
                <img id='logoff-logop' src={logoP} alt="Logo_Homepage" />
                <img id='logon-logo' src={logo} alt="Logo_Homepage" />
            </div>
        </div>
    );
};

export default Logon;
